import React from 'react';
import './App.css';
import { http, parseCookies, removeCookie } from './utils';
import { Authenticated, Page } from "./Page";
import { DocumentEditor } from "./DocumentEditor";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate
} from "react-router-dom"; 
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { i18n } from "./i18n";
import * as utils from "./utils";
import Form from "react-bootstrap/Form";

function Test() {
    let params = useParams();
    
    return (
        <>
            <div>TEST!!! {JSON.stringify(params)}</div>
            <Link to="/">Back to login</Link>
        </>
    );
}

function NotFound() {
    return (
        <>
            <div>404</div>
            <Link to="/">Back to main page</Link>
        </>
    );
}

function Main() {
    let [loading, setLoading] = React.useState(true);
    let [documents, setDocuments] = React.useState<any[]>([]);
    let navigate = useNavigate();
    
    React.useEffect(() => {
        setLoading(false);
        utils.http("/api/getDocuments").then(resp => {
            let docs = JSON.parse(resp);
            console.log(docs);
            setDocuments(docs);
        });
    }, []);
    
    const loadDocument = (doc: any) => {
        navigate('/document/' + doc.id);
    };
    
    const searchChange = (event: any) => {
        utils.http("/api/getDocuments/" + encodeURIComponent(event.target.value)).then(resp => {
            let docs = JSON.parse(resp);
            console.log(docs);
            setDocuments(docs);
        });
    };
    
    const newDoc = () => {navigate('/document')};
    
    return (<Page>
        <div style={{margin: "100px"}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <h1 style={{marginBottom: "50px"}}>Documents</h1>
                <Button onClick={newDoc} style={{alignSelf: "baseline"}} variant="light">{i18n.newDocument}</Button>
            </div>
            
            <Form.Control style={{width: "75%"}} onChange={searchChange} size="lg" type="text" placeholder="Search"/>
            
            <div>
                {documents.map((doc: any) => {
                    return <Card style={{ width: "100%"
                                        , cursor: "pointer"
                                        , background: "#f9f9f9"
                                        , margin: "10px 0"
                                       }}
                                 onClick={() => loadDocument(doc)}
                                 key={doc.id}
                           >
                               <Card.Body><Card.Title>{doc.title}</Card.Title></Card.Body>
                           </Card>;
                })}
            </div>
        </div>
    </Page>);
}

function App() {
    return (<>
        <BrowserRouter>
            <Routes>
                <Route path="/test/" element={<Test/>}>
                    <Route path=":param" element={<Test/>}/>
                </Route>
                <Route path="/document" element={<Authenticated><DocumentEditor/></Authenticated>}>
                    <Route path=":docId" element={<Authenticated><DocumentEditor/></Authenticated>}/>
                </Route>
                <Route path="/" element={<Authenticated><Main/></Authenticated>} />
                <Route path="*" element={<NotFound/>} />
            </Routes>
        </BrowserRouter>
    </>);
}

export default App;
