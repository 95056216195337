import * as React from "react";
import { useNavigate } from "react-router-dom";
import { http, setCookie } from './utils';
import styles from "./Login.module.css";
import { i18n } from "./i18n";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import logo100 from "./logo_100.png";

export function Login(props: {onLogin: () => void}) {
    let navigate = useNavigate();
    
    let [email, setEmail] = React.useState("");
    let [password, setPassword] = React.useState("");
    let [error, setError] = React.useState("");
    
    async function login() {
        let resp: any = JSON.parse(await http("/api/login", JSON.stringify({email, password})));
        if (resp.status == "ok") {
            setCookie("session", resp.session);
            props.onLogin?.();
        } else {
            setError(resp.message);
        }
    }
    
    return (
        <div className={styles.login}>
            <div>
                <h1>ExerciLab <img style={{width: "78px", marginLeft: "7px"}} src={logo100}/></h1>
                
                <div className={styles.form}>
                    {error && <div className="error">{error}</div>}
                    
                    <Form.Control style={{marginTop: "27px", marginBottom: "10px"}} onChange={evt => setEmail(evt.target.value)} size="sm" type="email" placeholder={i18n.email}/>
                    <Form.Control style={{marginBottom: "20px"}} onChange={evt => setPassword(evt.target.value)} size="sm" type="password" placeholder={i18n.password}/>
                    
                    <div><Button variant="light" onClick={login}>{i18n.login}</Button></div>
                </div>
            </div>
        </div>
    );
}
