import * as React from "react";
import { useNavigate } from "react-router-dom";
import { removeCookie, parseCookies, getCookies, setResetSessionHandler, resetSession } from "./utils";
import { Login } from "./Login";
import Button from "react-bootstrap/Button";
import styles from "./Page.module.css";
import { i18n } from "./i18n";

// Wrapper component for every page for which you need to be logged in
export const Authenticated: React.FunctionComponent = (props) => {
    let navigate = useNavigate();
    let [logged, setLogged] = React.useState(!!getCookies().session);
    
    setResetSessionHandler(() => {
        removeCookie("session");
        setLogged(false); // needed if you are already in /
        navigate("/");
    });
    
    function login() {
        setLogged(true);
    }
    
    return (<>
        {logged ? (<>
            {props.children}
        </>) : (
            <Login onLogin={login}/>
        )}
    </>);
};

// Wrapper component for header
export const Page: React.FunctionComponent<{header?: any}> = (props) => {
    let navigate = useNavigate();
    
    function logout() {
        resetSession();
    }
    
    function back() {
        navigate("/");
    }
    
    return (<>
        <div className={styles.header}>
            <span style={{fontSize: "23px", fontWeight: "bold", marginLeft: "30px", cursor: "pointer"}} onClick={back}>ExerciLab</span>
            {props.header}
            <Button variant="light" style={{float: "right"}} onClick={logout}>{i18n.logout}</Button>
        </div>
        {props.children}
    </>);
}
