import 'react-dropdown/style.css';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

export type DropdownOption = string | {label: string, value: string};

const Drop: React.FunctionComponent<{options: DropdownOption[], tabIndex?: number, noToggle?: boolean, text: any, onSelect: (value: string) => void}> = props => {
    let options: {label: string, value: string}[] = [];
    if (typeof props.options[0] == "string") {
        options = (props.options as string[]).map(s => ({value: s, label: s}));
    } else {
        options = (props.options as {label: string, value: string}[]);
    }
    
    const selectNewElement = (_: any, event: any) => {
        props.onSelect(event.target.attributes.value.value);
    };
    
    return (
        <DropdownButton tabIndex={props.tabIndex} className={props.noToggle ? "noToggle" : ""} variant="light" onSelect={selectNewElement} title={props.text}>
            {options.map(opt => <Dropdown.Item value={opt.value}>{opt.label}</Dropdown.Item>)}
        </DropdownButton>
    );
};

export { Drop as Dropdown };
