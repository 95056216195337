import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import styles from "./TagSelector.module.css";

const tagsData = `
    secondaire
        secondaire 1
            science
                physique
                biologie
            math
                trigonometrie
                fractions
        secondaire 2
        secondaire 3
        secondaire 4
        secondaire 5
    
    cegep
        calcul differentiel et integral
        biologie
            le domaine du vivant
            systeme immunitaire
            la cellule
        physique
            l'electricitee
            les forces
`;

interface TagTree {
    tag: string,
    childs: TagTree[]
}

const tags: TagTree[] = [];

(() => {
    let indentLevel = -1;
    let treeList: TagTree[] = [];
    
    tagsData.split('\n').forEach(line => {
        if (line.trim().length == 0)
            return;
        
        let firstChar = (/[^ ]/.exec(line) || [])[0];
        let firstCharIndex = line.indexOf(firstChar);
        let indent = firstCharIndex == -1 ? 0 : firstCharIndex/4;
        let tag = line.substr(firstCharIndex);
        
        if (indentLevel == indent) {
            treeList.pop();
        } else if (indent < indentLevel) {
            let diff = indentLevel - indent + 1;
            for (let x = 0 ; x < diff ; x++)
                treeList.pop();
        }
        
        let tt = {tag: tag, childs: []};
        treeList.push(tt);
        
        if (treeList.length == 1)
            tags.push(tt);
        else
            treeList[treeList.length-2].childs.push(tt);
        
        indentLevel = indent;
    });
})();

export const TagSelector: React.FunctionComponent<{finish: (path: string[]) => {}}> = props => {
    let [selectedPath, setSelectedPath] = React.useState<TagTree[]>([]);
    let [childs, setChilds] = React.useState<TagTree[]>(tags);
    
    const select = (tree: TagTree) => {
        setSelectedPath([...selectedPath, tree]);
        setChilds(tree.childs);
    };
    
    const back = () => {
        setSelectedPath(selectedPath.slice().reverse().slice(1).reverse());
        setChilds((selectedPath[selectedPath.length - 2] || {childs: undefined}).childs || tags);
    };
    
    const ok = () => {
        props.finish(selectedPath.map(t => t.tag));
    }
    
    return <div>
        <div>{selectedPath.map(t => t.tag).join(" > ")}</div>
        <div style={{display: "flex", flexWrap: "wrap"}}>
            {selectedPath.length > 0 && <Card className={styles.card} onClick={() => back()}>
                <Card.Body>
                    <Card.Title>
                        Back
                    </Card.Title>
                </Card.Body>
            </Card>}
            {childs.map(tree => {
                return <Card className={styles.card} onClick={() => select(tree)}>
                    <Card.Body>
                        <Card.Title>
                            {tree.tag}
                        </Card.Title>
                    </Card.Body>
                </Card>;
            })}
            {childs.length == 0 && <Card className={styles.card} onClick={() => ok()}>
                <Card.Body>
                    <Card.Title>
                        Ok
                    </Card.Title>
                </Card.Body>
            </Card>}
        </div>
    </div>;
};
