export type Timer = ReturnType<typeof setTimeout>;

let resetSessionHandler: any = undefined;
export function resetSession() {
    resetSessionHandler && resetSessionHandler();
}
export function setResetSessionHandler(handler: any) {
    resetSessionHandler = handler;
}

export function http(url: string, data?: string): Promise<string> {
    return new Promise((res, rej) => {
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function (e: any) {
            if (this.readyState == 4 && this.status == 200) {
                try {
                    let json = JSON.parse(e.target.responseText);
                    if (json.status == "error" && (json.code == "no session" || json.code == "bad session")) {
                        removeCookie("session");
                        resetSession();
                        rej("bad session");
                        return;
                    }
                } catch (e: any) {
                    // ...
                }
                res(e.target.responseText);
            } else if (this.readyState == 4) {
                rej(e);
            }
        };
        xhttp.open(data == undefined ? "GET" : "POST", url, true);
        xhttp.send(data);
    });
}

export function stringifyCookies(cookies: {[key: string]: string}): string {
    var list = [];
    for (var key in cookies) {
        list.push(key + '=' + encodeURIComponent(cookies[key]));
    }
    return list.join('; ');
}

let cookies: {[key: string]: string} = {};
updateCookies();
function updateCookies() {
    cookies = parseCookies(document.cookie);
}
export function getCookies(): {[key: string]: string} {
    return cookies;
}

export function parseCookies(cookie: string): {[key: string]: string} {
    if (cookie.length == 0)
        return {};
    
    return cookie.split(';').reduce(
        function(prev: any, curr: any) {
            let m = / *([^=]+)=(.*)/.exec(curr) as any;
            let key = m[1];
            let value = decodeURIComponent(m[2]);
            prev[key] = value;
            return prev;
        },
        { }
    );
}

export function setCookie(name: string, value: string) {
    document.cookie = name + "=" + value + "; expires=Thu, 1 Dec 2100 12:00:00 UTC";
    updateCookies();
}

export function removeCookie(name: string): void {
    document.cookie = name + "=0;expires=Thu, 01 Jan 1970 00:00:01 GMT";
    updateCookies();
}

export function newId(): number {
    return Math.floor(Math.random()*2147483646);
}

export function isEmpty(str: string): boolean {
    return str.length == 0 || ["<br>", "</br>", "<BR>", "</BR>"].includes(str);
}
