import React from "react";
import { Page } from "./Page";
import { Link } from "react-router-dom";
import styles from "./DocumentEditor.module.css";
import xIcon from "./xIcon.png";
import { Dropdown } from "./Dropdown";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { ContentEditable } from "./ContentEditable";
import { i18n } from "./i18n";
import * as utils from "./utils";
import { TagSelector } from "./TagSelector";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom"; 

interface Document {
    id?: number,
    parts: DocPart[]
}

interface DocPart {
    id: number,
    type: string,
    unremovable?: boolean
}

interface DocPartText extends DocPart {
    type: "text",
    tagType: "h1" | "h2" | "h3" | "p",
    text: string
}

interface DocPartQuestion extends DocPart {
    type: "question",
    text: string,
    tags: string[],
    choices: {
        id: string,
        text: string,
        focus?: boolean
    }[]
}

interface DocInterface {
    addPart: (part: Omit<DocPart, "id">, i: number) => void,
    openSearch: (i: number) => void
}

const DocPartWrapper: React.FunctionComponent<{onRemove: () => void, part: DocPart, leftSection?: any, i: number, docInterface: DocInterface, style?: any}> = (props) => {
    const options = [ {label: "Title", value: "h1"}
                    , {label: "Text", value: "p"}
                    , {label: "question", value: "question"}
                    , {label: "search", value: "search"}
                    ];
    
    const selectNewElement = (newValue: string) => {
        if (newValue == "question") {
            props.docInterface.addPart({type: "question", tags:[], text: "", choices: [{id: Math.random()+"",text: ""}]} as Omit<DocPartQuestion, "id">, props.i + 1);
        } else if (newValue == "search") {
            props.docInterface.openSearch(props.i);
        } else {
            props.docInterface.addPart({type: "text", tagType: newValue, text: ""} as Omit<DocPartText, "id">, props.i + 1);
        }
    };
    
    return (
        <div style={{marginBottom: "10px", ...props.style}} className={styles.documentPart}>
            <div className={styles.leftSection}>{props.leftSection || ""}</div>
            <div className={styles.middleSection}>{props.children}</div>
            <div className={styles.rightSection}>
                <Dropdown tabIndex={-1} text="add" options={options} onSelect={selectNewElement}/>&nbsp;
                {!props.part.unremovable && <Button tabIndex={-1} variant="light" onClick={props.onRemove}><img className={styles.xIcon} src={xIcon}/></Button>}
            </div>
        </div>
    );
};

function useUpdater() {
    let [updateCounnter, update] = React.useState(0);
    return () => {update(updateCounnter + 1)};
}

const DocPartQuestion: React.FunctionComponent<{onRemove: () => void, part: DocPartQuestion, docInterface: DocInterface, i: number}> = (props) => {
    let update = useUpdater();
    let [showModal, setShowModal] = React.useState(false);
    
    (window as any).test = props.part;
    
    const textChange = (evt: any) => {
        props.part.text = evt;
    };
    
    const choiceChange = (v: string, i: number) => {
        props.part.choices[i].text = v;
    }
    
    const removeChoice = (i: number) => {
        props.part.choices.splice(i, 1);
        update();
    };
    
    const add = (i: number) => {
        props.part.choices.splice(i+1, 0, {id: utils.newId() + "", text: "", focus: true});
        update();
    };
    
    const selectedTags = async (tags: string[]) => {
        props.part.tags = tags;
        setShowModal(false);
        let resp: any = JSON.parse(await utils.http("/api/saveQuestion", JSON.stringify(props.part)));
        if (resp.status == "ok") {
            if (resp.id)
                props.part.id = resp.id;
        }
    };
    
    const moreOptions = [{label: i18n.save, value: "save"}];
    const selectMore = (selected: string) => {
        if (selected == "save") {
            setShowModal(true);
        }
    };
    
    const onKeyDown = (evt: any, i: number) => {
        if (evt.keyCode == 13 && evt.shiftKey) {
            evt.preventDefault();
            add(i);
        } else if (evt.keyCode == 8 && utils.isEmpty(props.part.choices[i].text)) {
            removeChoice(i);
            //props.part.choices[i-1].focus = true;
        }
    };
    
    const rendered = <DocPartWrapper leftSection={<Dropdown tabIndex={-1} text={<span className="dotdotdot">...</span>} noToggle={true} options={moreOptions} onSelect={selectMore}/>}
                           i={props.i}
                           part={props.part}
                           docInterface={props.docInterface}
                           
                           onRemove={props.onRemove}
                           style={{margin: "20px 0 50px 0"}}
           >
        <Modal
              size="lg"
              centered
              show={showModal}
              onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Save
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                <TagSelector finish={selectedTags}/>
            </Modal.Body>
            
            <Modal.Footer>
                <Button onClick={() => setShowModal(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
        
        <ContentEditable tag="h3" style={{marginBottom: "35px"}} onChange={textChange} html={props.part.text}/>
        {props.part.choices.map((choice, i) => {
            return <div key={choice.id} className={styles.choice}>
                <div className={styles.choiceLeftSection}>
                    {String.fromCharCode(65+i)})
                </div>
                <div style={{flexGrow: 1}}><ContentEditable focus={choice.focus} tag="p" onKeyDown={(evt: any) => onKeyDown(evt, i)} onChange={(v: string) => choiceChange(v, i)} html={choice.text}/></div>
                <div className={styles.choiceRightSection}>
                    <Button tabIndex={-1} variant="light" onClick={() => add(i)}><img className={styles.xIcon} style={{transform: "rotate(45deg)"}} src={xIcon}/></Button>
                    {(props.part.choices.length > 1) && <Button tabIndex={-1} variant="light" style={{marginLeft: "10px"}} onClick={() => removeChoice(i)}><img className={styles.xIcon} src={xIcon}/></Button>}
                </div>
            </div>;
        })}
    </DocPartWrapper>;
    
    // Cleanup
    props.part.choices.forEach(choice => { delete choice.focus });
    
    return rendered;
};

const DocPartText: React.FunctionComponent<{onRemove: () => void, part: DocPartText, docInterface: DocInterface, i: number}> = (props) => {
    let update = useUpdater();
    
    const change = (value: string) => {
        props.part.text = value;
    };
    
    let options = ["h1", "h2", "h3", "p"];
    
    const tagChange = (newValue: any) => {
        props.part.tagType = newValue;
        update();
    }
    
    
    const Tag = props.part.tagType;
    
    return <DocPartWrapper leftSection={<div>
                                            <Dropdown tabIndex={-1} text={props.part.tagType} options={options} onSelect={tagChange}/>
                                        </div>}
                           i={props.i}
                           part={props.part}
                           docInterface={props.docInterface}
                           onRemove={props.onRemove}
           >
        <ContentEditable tag={props.part.tagType} onChange={change} html={props.part.text}/>
    </DocPartWrapper>;
}

const DocPart: React.FunctionComponent<{part: DocPart, onRemove: any, docInterface: DocInterface, i: number}> = (props) => {
    if (props.part.type == "text") {
        return <DocPartText docInterface={props.docInterface} i={props.i} part={props.part as DocPartText} onRemove={props.onRemove}/>;
    } else if (props.part.type == "question") {
        return <DocPartQuestion docInterface={props.docInterface} i={props.i} part={props.part as DocPartQuestion} onRemove={props.onRemove}/>;
    } else {
        return <div>Error bad part type {props.part.type}</div>;
    }
}

export function DocumentEditor() {
    let params = useParams();
    let [doc, setDoc] = React.useState<false | Document>(false);
    let [searchState, setSearchState] = React.useState<any>({input: "", open: false, i: 0, results: []});
    
    React.useEffect(() => {
        if (params.docId) {
            utils.http("/api/getDocument/" + params.docId).then(docString => {
                let doc: any = JSON.parse(docString);
                doc.id = params.docId;
                setDoc(doc);
            });
        } else {
            doc = {parts: []};
            
            setDoc(doc);
            
            docInterface.addPart({type: "text", tagType: "h1", unremovable: true, text: ""} as DocPartText, 0);
            docInterface.addPart({type: "text", tagType: "p", text: ""} as DocPartText, 1);
        }
    }, []);
    
    function removePart(index: number) {
        if (doc && doc.parts.length > 1) {
            doc.parts.splice(index, 1);
            setDoc({...doc});
        }
    }
    
    const docInterface = {
        addPart: (part: Omit<DocPart, "id">, i: number) => {
            (part as DocPart).id = utils.newId();
            if (doc) {
                doc.parts.splice(i, 0, part as DocPart);
                setDoc({...doc});
            }
        },
        openSearch: (i: number) => {
            searchState.i = i;
            searchState.open = true;
            searchState.results = [];
            setSearchState({...searchState});
        }
    };
    
    const searchChange = (v: any) => {
        searchState.input = v.target.value;
        utils.http("/api/searchQuestions/" + encodeURIComponent(searchState.input)).then((r: any) => {
            let resp: any = JSON.parse(r);
            searchState.results = resp.map((d: any) => JSON.parse(d.data));
            setSearchState({...searchState});
        });
    };
    
    const selectQuestion = (i: number) => {
        let question = searchState.results[i];
        docInterface.addPart(question as DocPart, searchState.i + 1);
        searchState.open = false;
        setSearchState({...searchState});
        if (doc) {
            setDoc({...doc});
        }
    };
    
    const saveDocument = async () => {
        let resp: any = JSON.parse(await utils.http("/api/saveDocument", JSON.stringify(doc)));
        if (resp.status == "ok" && doc) {
            if (resp.id != undefined) {
                doc.id = resp.id;
                setDoc({...doc});
            }
        }
    };
    const saveAsNewDocument = async () => {
        if (doc) {
            delete doc.id;
            saveDocument();
            setDoc({...doc});
        }
    };
    
    return (<Page header={<>
                <Button variant="light" style={{marginLeft: "20px"}} onClick={saveDocument}>{i18n.save}</Button>
                {doc && doc.id && <Button variant="light" style={{marginLeft: "20px"}} onClick={saveAsNewDocument}>{i18n.saveAsNew}</Button>}
           </>}>
        {searchState.open && <div style={{height: 0}}>
             <div className={styles.searchPanel}>
                 <div style={{display: "flex", justifyContent: "space-between"}}>
                     <h2>Search</h2>
                     <Button variant="light" style={{alignSelf: "center"}} onClick={() => setSearchState({...searchState, open: false})}><img className={styles.xIcon} src={xIcon}/></Button>
                 </div>
                 <Form.Control onChange={searchChange} style={{marginBottom: "10px"}} size="lg" type="text" placeholder="Search"/>
                 <div style={{overflowY: "scroll", height: "inherit", padding: "10px 0"}}>
                     {searchState.results.map((q: any, i: number) => {
                         return <Card onClick={() => selectQuestion(i)} style={{margin: "5px 0", cursor: "pointer"}}>
                             <Card.Body>
                                 <Card.Title>{q.text}</Card.Title>
                                 <Card.Text>
                                    {q.choices.map((choice: any, i: number) => {
                                        return <div className={styles.choice}>
                                            <div className={styles.choiceLeftSection}>
                                                {String.fromCharCode(65+i)})
                                            </div>
                                            <div style={{flexGrow: 1}}><p>{choice.text}</p></div>
                                        </div>;
                                    })}
                                 </Card.Text>
                             </Card.Body>
                         </Card>;
                     })}
                 </div>
             </div>
        </div>}
        <div className={styles.documentContainer}>
            {doc && doc.parts.map((part, i) => <DocPart i={i} key={part.id} docInterface={docInterface} part={part} onRemove={() => removePart(i)}/>)}
        </div>
    </Page>);
}
