import React from 'react';
import { i18n } from "./i18n";
import * as utils from "./utils";

export function ContentEditable(props: any) {
    const ref = React.useRef(null);
    const placeholder = React.useRef<any>(null);
    
    React.useEffect(() => {
        if (props.focus && ref.current) {
            (ref.current as any).focus();
        }
    },[]);
    
    let isEmpty = true;
    function updateIsEmpty() {
        isEmpty = utils.isEmpty(props.html);
    }
    updateIsEmpty();
    
    function emitChange() {
        let html = (ref.current! as any).innerHTML;
        props.html = html;
        
        updateIsEmpty();
        if (placeholder.current) {
            placeholder.current.style.display = isEmpty ? "block" : "none";
        }
        
        if (props.onChange)
            props.onChange(html);
    }
    
    function onKeyDown(evt: any) {
        if (props.onKeyDown) {
            props.onKeyDown(evt);
        }
    }
    
    let Tag = props.tag;
    
    return (<>
        <Tag ref={placeholder} style={{display: isEmpty ? "block" : "none"
                                      , height: 0
                                      , color: "#ddd"
                                      , margin: 0
                                      , padding: 0
                                      , pointerEvents: "none"
                                      }}>{i18n.typeHere}</Tag>
        <Tag onInput={emitChange}
             onBlure={emitChange}
             onKeyDown={onKeyDown}
             ref={ref}
             style={props.style}
             contentEditable
             dangerouslySetInnerHTML={{__html: props.html}}>
        </Tag>
    </>);
}

