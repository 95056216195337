const fr = {
    logout: "Déconnection",
    add: "Ajouter",
    email: "couriel",
    password: "mot de passe",
    login: "Connecter",
    documents: "Documents",
    newDocument: "Nouveau document",
    typeHere: "Écrire ici",
    save: "Enregistrer",
    saveAsNew: "Enregistrer comment nouveaux document"
};

const en: typeof fr = {
    logout: "Logout",
    add: "Add",
    email: "email",
    password: "password",
    login: "Login",
    documents: "Documents",
    newDocument: "Create a new document",
    typeHere: "Type here",
    save: "Save",
    saveAsNew: "Save as new document"
};

const languages = {
    en,
    fr
};

const i18n: typeof fr = {} as any;

let selectedLanguage: keyof typeof languages = "" as any;

function selectLanguage(lang: keyof typeof languages) {
    selectedLanguage = lang;
    let trads = languages[selectedLanguage];
    Object.keys(trads).forEach((k: any) => {
        (i18n as any)[k] = (trads as any)[k];
    });
}

selectLanguage("fr");

export { i18n };
